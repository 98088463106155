<script>
import CustomCheckbox from "@/components/Forms/Fields/CustomCheckbox.vue";
import ImageRenderer from "@/components/Tables/CellRenderers/ImageRenderer.vue";
import GroupsRenderer from "@/components/Tables/CellRenderers/UserGroupsRenderer.vue";
import EditRenderer from "@/components/Tables/CellRenderers/EditRenderer.vue";
import AuthorizationRenderer from "@/components/Tables/CellRenderers/AuthorizationRenderer.vue";
import TextLinkRenderer from "@/components/Tables/CellRenderers/TextLinkRenderer.vue";

export default {
  name: "BaseTable",
  components: {
    TextLinkRenderer,
    AuthorizationRenderer,
    EditRenderer,
    GroupsRenderer,
    ImageRenderer,
    CustomCheckbox
  },
  props: {
    columns: Object,
    rows: Object,
    rowSelect: Function,
    searchParams: Object,
    checkedList: Array,
  },
}
</script>

<template>
  <div class="base_table_wrapper">
    <table class="base_table">
      <tr>
        <template v-for="(column, index) in columns" v-bind:key="index">
          <th class="table_header">
            {{ column.headerName }}
          </th>
        </template>
      </tr>
      <tr v-for="row in rows" class="table_row" :key="row.id">
        <template v-for="(column, index) in columns" v-bind:key="index">
          <td>
            <div class="cell_wrapper">
              <CustomCheckbox v-if="column.field === 'check'" @change="rowSelect" :data-id=row.id :checked="checkedList.includes(row.id)" />

              <span v-if="column.field !== 'eiToken' && column.field !== 'photo'" class="mobile_header">{{ column.headerName }}</span>

              <div v-if="column.field === 'photo'" class="photo_cell">
                <ImageRenderer :image="row.photo"/>
              </div>

              <div v-else-if="column.field === 'groups'" class="group_cell">
                <GroupsRenderer :groups="row.groups"/>
              </div>

              <div v-else-if="column.field === 'name'">
                <TextLinkRenderer :data_id="row.id" :data_name="row.name" :dest="'staff'" :res="'edit'" />
              </div>

              <div v-else-if="column.field === 'eiToken'" class="auth_button_cell">
                <AuthorizationRenderer :id="row.id" :user_role="row.role"/>
              </div>

              <div v-else-if="column.field === 'edit'" class="edit_cell">
                  <EditRenderer :val="row.id" :dest="'staff'" :res="'edit'"/>
              </div>

              <p v-else>
                {{ row[column.field] ?? '' }}
              </p>
            </div>
          </td>
        </template>
      </tr>
    </table>
  </div>
</template>

<style scoped lang="scss">

td, th {
  padding: 10px;
}

tr:nth-child(even) {
  background-color: var(--cream-color);
}

.base_table_wrapper {
  width: 100%;
  overflow: auto;
}

.base_table {
  border-collapse: collapse;
}

.table_header {
  min-height: 60px;
  font-size: 15px;
  color: var(--common-text-color);
}

.employee_table {
  width: 100%;
}

.cell_wrapper {
  display: flex;
  min-width: 50px;
}

.mobile_header {
  display: none;
}

.check {
  margin-top: 15px;
  margin-left: 15px;
  width: 50px;
  height: 50px;
}

.auth_button_cell,
.group_cell {
  width: 200px;
}

@media (max-width: 800px) {
  tr {
    display: flex;
    flex-direction: column;
  }

  td {
    width: 100%;
  }

  .table_header {
    display: none;
  }

  .mobile_header {
    display: block;
    margin-right: 10px;
    font-weight: bold;
    color: var(--common-text-color);
  }

  .cell_wrapper {
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 5px;
  }

  .group_cell {
    text-align: right;
  }

  .base_table_wrapper {
    margin-top: 25px;
  }

  .auth_button_cell,
  .photo_cell {
    margin: auto;
  }

  .edit_cell {
    margin-right: 10px;
  }
}
</style>